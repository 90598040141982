'use strict';
$('form.contactForm').on('submit', function(e) {
    e.preventDefault();

    var formData = new FormData($(this)[0]);
    var action = $(this).attr('action');

    $.ajax({
        url: action,
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function(data) {
            var response = jQuery.parseJSON(data);

            if(response.success) {
                $('span.text-danger').remove();
                $('form.contactForm').find('input, textarea').val('');
                $('.successMessage').fadeIn(500);

                setTimeout(function() {
                    $('.successMessage').fadeOut(1000);
                }, 5000);
            } else {
                $('span.text-danger').remove();
                $.each(response.errors, function(k, v) {
                    $('#' + k).parent().append('<span class="text-danger">' + v + '</span>');
                });
            }
        }
    });
});