'use strict';

$('.slider').slick({
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: 4000
});

$('.slider').on('mouseover', function() {
    $(this).slick('pause');
});

$('.slider').on('mouseout', function() {
    $(this).slick('play');
});