'use strict';

$('.gallery img').each(function() {

    var width = $(this).width();
    var height = $(this).height();

    /*
    $(this).css({
        left: 0 - (width / 2),
        top: 0 - (height / 2)
    });
    */

});