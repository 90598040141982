'use strict';

$('.openGallery').on('click', function(e) {

    e.preventDefault();
    var images = [
        {
            href: "/assets/img/vermietung/1og/Entree.jpg",
            title: "Test"
        },
        {
            href: "/assets/img/vermietung/1og/EinbaukuecheBulthaupt.jpg",
            title: "Test"
        },
        {
            href: "/assets/img/vermietung/1og/Gaestebad.jpg",
            title: "Test"
        },
        {
            href: "/assets/img/vermietung/1og/Masterbad.jpg",
            title: "Test"
        },
        {
            href: "/assets/img/vermietung/1og/Masterbedroom.jpg",
            title: "Test"
        },
        {
            href: "/assets/img/vermietung/1og/WE%201.10_Grundriss.jpg",
            title: "Test"
        },
        {
            href: "/assets/img/vermietung/1og/Wohnraum.jpg",
            title: "Test"
        }
    ];

    $.fancybox.open(
        images,
        {
            padding: 0,
            helpers: {
                title: {}
            }
        }
    );

});