'use strict';

(function() {

    $('a[href^="mailto:"]').each(function() {

        this.href = this.href.replace('(at)', '@').replace(/\(dot\)/g, '.');
        $(this).html(this.href.replace('mailto:', ''));

    });

}());